
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB7NhDlTazmQQRC2eyb-10Z6nUV0-UpLwA",
  authDomain: "sonerware-software.firebaseapp.com",
  projectId: "sonerware-software",
  storageBucket: "sonerware-software.appspot.com",
  messagingSenderId: "136115879369",
  appId: "1:136115879369:web:6510d49d2e5ac123abbc18",
  measurementId: "G-S80DP8E8GZ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const auth = getAuth(app);

export { db };


