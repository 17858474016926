import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress, Button } from "@mui/material";

export default function Footer() {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_ano5ffu",
        "template_7j51ano",
        form.current,
        "6vqxdHTxvc4gT-6TU"
      )
      .then(
        () => {
          setLoading(false);

          toast.success("Message sent successfully!");
        },
        (error) => {
          setLoading(false);

          toast.error("Failed to send message. Please try again.");
          console.error("FAILED...", error.text);
        }
      );
  };

  return (
    <footer id="contact-us">
      <div className="container">
        <div className="footer-content">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="contact-form">
                <form ref={form} onSubmit={sendEmail}>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <fieldset>
                        <input
                          name="user_name"
                          type="text"
                          id="name"
                          placeholder="Full Name"
                          required=""
                          style={{
                            backgroundColor: "rgba(250, 250, 250, 0.3)",
                          }}
                        />
                      </fieldset>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <fieldset>
                        <input
                          name="user_email"
                          type="email"
                          id="email"
                          placeholder="E-Mail Address"
                          required=""
                          style={{
                            backgroundColor: "rgba(250, 250, 250, 0.3)",
                          }}
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <textarea
                          name="message"
                          rows="6"
                          id="message"
                          placeholder="Your Message"
                          required=""
                          style={{
                            backgroundColor: "rgba(250, 250, 250, 0.3)",
                          }}
                        ></textarea>
                      </fieldset>
                    </div>
                    {/* <div className="col-lg-12">
                      <fieldset>
                        <button
                          type="submit"
                          id="form-submit"
                          className="main-button"
                        >
                          Send Message Now
                        </button>
                      </fieldset>
                    </div> */}
                    <div className="col-lg-12">
                      <fieldset>
                        <Button
                          type="submit"
                          id="form-submit"
                          className="main-button"
                          disabled={loading} // Yükleme devam ediyorsa butonu devre dışı bırak
                        >
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Send Message Now"
                          )}
                        </Button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="right-content col-lg-6 col-md-12 col-sm-12">
              <h2>
                More About <em>Sonerware Software</em>
              </h2>
              <p>
                Discover how Sonerware Software can help you achieve your
                business goals with our innovative solutions. Our team
                specializes in creating cutting-edge applications and providing
                expert digital consulting. Get in touch to learn more about our
                services and how we can assist you.
              </p>
              <ul className="social">
                <li>
                  <a href="https://fb.com/templatemo">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-rss"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-dribbble"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="sub-footer">
              <p>
                Copyright &copy; 2020 Lava Landing Page | Designed by{" "}
                <a rel="nofollow" href="https://templatemo.com">
                  TemplateMo
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </footer>
  );
}
