// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import portfolioReducer from './slices/portfolioSlice';
import customerRateReducer from "./slices/customerRateSlice";

export const store = configureStore({
  reducer: {
    portfolio: portfolioReducer,
    customerRates: customerRateReducer,
  },
});

