
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux'; 
import { store } from './store'; 
import WelcomePage from "./pages/WelcomePage";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import TestimonialsPage from "./pages/TestimonialsPage";
import ContactUsPage from "./pages/ContactUsPage";
import ShowroomPage from './pages/ShowroomPage';
import FeaturesPage from './pages/FeaturesPage';
import PortfolioPage from './pages/PortfolioPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />
      },
      {
        path: "/home",
        element: <HomePage />
      },
      {
        path: "/welcome",
        element: <WelcomePage />
      },
      {
        path: "/about",
        element: <AboutPage />
      },
      {
        path: "/testimonials",
        element: <TestimonialsPage />
      },
      {
        path: "/ourProjects",
        element: <PortfolioPage />
      },
      {
        path: "/contactUs",
        element: <ContactUsPage />
      },
      {
        path: "/showroom",
        element: <ShowroomPage />
      },
      {
        path: "/features",
        element: <FeaturesPage />
      }
    ],
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>  {/* Redux Provider ile sarıyoruz */}
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
