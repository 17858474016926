import React from 'react'
import Header from "../components/Header"
import Navigation from "../components/Navigation"
import FeaturesPage from './FeaturesPage'
import ShowroomPage from './ShowroomPage'
import TestimonialsPage from './TestimonialsPage'
import Footer from '../components/Footer'
import WelcomePage from './WelcomePage'
import PortfolioPage from './PortfolioPage';

export default function HomePage() {
  return (
    <>
      <Header />
      <Navigation />
      <WelcomePage />
      <PortfolioPage />
      <FeaturesPage />
      <div className="left-image-decor"></div>
      <ShowroomPage />
      <div className="right-image-decor"></div>
      <TestimonialsPage />
      <Footer />
    </>
  );
}
