// src/slices/portfolioSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../firebaseConfig'; // Firebase Firestore bağlantısı

// Firebase'den verileri çekmek için async thunk
export const fetchApps = createAsyncThunk('portfolio/fetchApps', async () => {
  const querySnapshot = await getDocs(collection(db, "projects")); // 'projects' koleksiyonuna erişim
  const apps = [];
  querySnapshot.forEach((doc) => {
    apps.push({ id: doc.id, ...doc.data() });
  });
  return apps;
});

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: {
    apps: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchApps.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.apps = action.payload;
      })
      .addCase(fetchApps.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default portfolioSlice.reducer;
