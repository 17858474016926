import React from 'react'

export default function WelcomePage() {
  return (
    <div className="welcome-area" id="welcome">
      <div className="header-text">
        <div className="container">
            <div className="row">
                <div className="left-text col-lg-6 col-md-12 col-sm-12 col-xs-12"
                    data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                    {/* <h1>Empowering Businesses with <em>Innovative Technology</em></h1> */}
                    <h1>Your Partner in <em>Digital Transformation</em></h1>
                    <p>Our mission is to empower businesses by providing innovative software solutions that enhance efficiency, foster growth, and create new opportunities. Discover how our tailored services can help you achieve your goals.</p> 
                    <a href="#about" className="main-button-slider">KNOW US BETTER</a>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}