import React from 'react';

export default function FeaturesPage() {
  return (
    <section className="section" id="about">
          <div className="container">
              <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                      data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                      <div className="features-item">
                          <div className="features-icon">
                              <h2>01</h2>
                              <img src="images/features-icon-1.png" alt="" />
                              <h4>Mobile App Development</h4>
                              <p>We build high-performance mobile apps using Flutter. Our apps work perfectly on both iOS and Android devices.</p>
                              <a href="#testimonials" className="main-button">
                                  Read More
                              </a>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                      data-scroll-reveal="enter bottom move 30px over 0.6s after 0.4s">
                      <div className="features-item">
                          <div className="features-icon">
                              <h2>02</h2>
                              <img src="images/features-icon-2.png" alt="" />
                              <h4>Web Development</h4>
                              <p>We design modern and user-friendly websites. Strengthen your online presence with our professional web solutions.</p>
                              <a href="#testimonials" className="main-button">
                                  Discover More
                              </a>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                      data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                      <div className="features-item">
                          <div className="features-icon">
                              <h2>03</h2>
                              <img src="images/features-icon-3.png" alt="" />
                              <h4>Digital Consulting</h4>
                              <p>We offer digital transformation consulting services. We provide technological solutions to give your business a competitive edge.</p>
                              <a href="#testimonials" className="main-button">
                                  More Detail
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  );
}
