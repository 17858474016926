// App.js
import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import './css/snackbarStyles.css'; 

function App() {
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;

    fetch("https://ipapi.co/json/")
      .then(response => response.json())
      .then(data => {
        const userCountry = data.country;

        if (userLanguage.startsWith("tr") || userCountry === "TR") {
          setShowSnackbar(true);
        }
      })
      .catch(error => console.log("Konum tespiti yapılamadı:", error));
  }, []);

  const dismissSnackbar = () => {
    localStorage.setItem("languageChoice", "dismissed");
    setShowSnackbar(false);
  };

  return (
    <div className="App">
      <Outlet />
      {showSnackbar && (
        <div id="language-snackbar" className="snackbar">
          Bu siteyi Türkçe görmek ister misiniz? 
          <a href="https://sonerwaresoftware.com.tr" className="snackbar-link">Türkçe Sürüm</a>
          <button onClick={dismissSnackbar} className="snackbar-dismiss">Kapat</button>
        </div>
      )}
    </div>
  );
}

export default App;
