// import React from 'react'

// export default function TestimonialsPage() {
//   return (
//     <section className="section" id="testimonials">
//           <div className="container">
//               <div className="row">
//                   <div className="col-lg-8 offset-lg-2">
//                       <div className="center-heading">
//                           <h2>What They Think <em>About Us</em></h2>
//                           <p>We are proud to have worked with numerous clients who have shared their positive experiences with us. Below, you'll find genuine feedback from those who have trusted us with their projects. We believe that our commitment to delivering high-quality solutions is reflected in their kind words. See what our clients have to say about working with us!</p>
//                       </div>
//                   </div>
//                   <div className="col-lg-10 col-md-12 col-sm-12 mobile-bottom-fix-big"
//                       data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
//                       <div className="owl-carousel owl-theme">
//                           <div className="item service-item">
//                               <div className="author">
//                                   <i><img src="images/testimonial-author-1.png" alt="Author One" /></i>
//                               </div>
//                               <div className="testimonial-content">
//                                   <ul className="stars">
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                   </ul>
//                                   <h4>Dilara Bağçaaltı</h4>
//                                   <p>“Tamamen sıfırdan bir mobil uygulama istedim ve beklediğimden çok daha güzel bir uygulama ortaya çıkardılar.”</p>
//                                   <span>ÇeyizBuddy</span>
//                               </div>
//                           </div>
//                           <div className="item service-item">
//                               <div className="author">
//                                   <i><img src="images/testimonial-author-1.png" alt="Second Author" /></i>
//                               </div>
//                               <div className="testimonial-content">
//                                   <ul className="stars">
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                   </ul>
//                                   <h4>Dt. Betül Mumcu Arı</h4>
//                                   <p>“Morbi non mi luctus felis molestie scelerisque. In ac libero viverra, placerat est
//                                       interdum, rhoncus leo.”</p>
//                                   <span>Web Analyst</span>
//                               </div>
//                           </div>
//                           <div className="item service-item">
//                               <div className="author">
//                                   <i><img src="images/testimonial-author-1.png" alt="Author Third" /></i>
//                               </div>
//                               <div className="testimonial-content">
//                                   <ul className="stars">
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                   </ul>
//                                   <h4>Dt. Fehmi Bora Şariç</h4>
//                                   <p>“Fusce rutrum in dolor sit amet lobortis. Ut at vehicula justo. Donec quam dolor,
//                                       congue a fringilla sed, maximus et urna.”</p>
//                                   <span>System Admin</span>
//                               </div>
//                           </div>
//                           <div className="item service-item">
//                               <div className="author">
//                                   <i><img src="images/testimonial-author-1.png" alt="Fourth Author" /></i>
//                               </div>
//                               <div className="testimonial-content">
//                                   <ul className="stars">
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                       <li><i className="fa fa-star"></i></li>
//                                   </ul>
//                                   <h4>Sir James</h4>
//                                   <p>"Fusce rutrum in dolor sit amet lobortis. Ut at vehicula justo. Donec quam dolor,
//                                       congue a fringilla sed, maximus et urna."</p>
//                                   <span>New Villager</span>
//                               </div>
//                           </div>
//                       </div>
//                   </div>
//               </div>
//           </div>
//       </section>
//   )
// }
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerRates } from "../slices/customerRateSlice";

export default function TestimonialsPage() {
  const dispatch = useDispatch();
  const { rates, status, error } = useSelector((state) => state.customerRates);

  useEffect(() => {
    dispatch(fetchCustomerRates());
  }, [dispatch]);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="section" id="testimonials">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="center-heading">
              <h2>What They Think <em>About Us</em></h2>
              <p>
                We are proud to have worked with numerous clients who have shared their positive experiences with us. Below, you'll find genuine feedback from those who have trusted us with their projects. We believe that our commitment to delivering high-quality solutions is reflected in their kind words. See what our clients have to say about working with us!
              </p>
            </div>
          </div>

          <div className="col-lg-10 col-md-12 col-sm-12 mobile-bottom-fix-big">
            <div className="owl-carousel owl-theme">
              {rates.map((rate) => (
                <div key={rate.id} className="item service-item">
                  <div className="author">
                    <i>
                    <img src={rate.imageUrl} alt={`${rate.name} ${rate.surname}`} />
                    </i>
                  </div>
                  <div className="testimonial-content">
                    <ul className="stars">
                      {Array(Math.round(rate.rate))
                        .fill(0)
                        .map((_, i) => (
                          <li key={i}>
                            <i className="fa fa-star"></i>
                          </li>
                        ))}
                    </ul>
                    <h4>{`${rate.name} ${rate.surname}`}</h4>
                    <p>{rate.opinion}</p>
                    <span>{rate.appName}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

