import React from 'react'
import logo from '../assets/logo/ss_logo.png'

export default function Navigation() {
  return (
    <header className="header-area header-sticky">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <nav className="main-nav">
                          {/* <a href="#welcome" className="logo">
                              Sonerware
                          </a> */}

                            <a href="#welcome" className="logo">
                                <img src={ logo } alt="logo" /> 
                                <div>
                                    Sonerware <br />
                                    <span style={{ fontSize: '14px', fontWeight: 'normal', display: 'block', lineHeight: '1.2' }}>Software</span>
                                </div>
                            </a>
                          <ul className="nav">
                              <li className="scroll-to-section"><a href="#welcome" className="menu-item">Home</a></li>
                              <li className="submenu">
                                  <a href="#features" className="menu-item">Products</a>
                                  <ul>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">ÇeyizBuddy</a></li>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">Dt. Betül Mumcu Klinik</a></li>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">Dünyamızı Tanıyalım</a></li>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">neyAppsam</a></li>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">EzTrip</a></li>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">Dt. Fehmi Bora Şariç</a></li>
                                  </ul>
                              </li>
                              <li className="scroll-to-section"><a href="#about" className="menu-item">About</a></li>
                              <li className="scroll-to-section"><a href="#promotion" className="menu-item">Features</a></li>
                              <li className="scroll-to-section"><a href="#testimonials" className="menu-item">Testimonials</a></li>
                              <li className="scroll-to-section"><a href="#contact-us" className="menu-item">Contact Us</a></li>
                          </ul>
                          <a className='menu-trigger'>
                              <span>Menu</span>
                          </a>
                      </nav>
                  </div>
              </div>
          </div>
      </header>
  )
}

