import React, { useEffect } from 'react'

export default function Header() {
  useEffect (() => {
    const spinnerTimeout = setTimeout(() => {
    const spinnerElement = document.getElementById("preloader");
    if(spinnerElement)
    {
    spinnerElement.classList.remove("show")
    }
    }, 2000)
    return () => {
    clearTimeout(spinnerTimeout)
    }
    
    }, [])

  return (
    <div id="preloader">
          <div className="jumper">
              <div></div>
              <div></div>
              <div></div>
          </div>
      </div>

  )
}
