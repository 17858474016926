import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const fetchCustomerRates = createAsyncThunk(
  "customerRates/fetchCustomerRates",
  async () => {
    const customerRateCollection = collection(db, "customerRate");
    const customerRateSnapshot = await getDocs(customerRateCollection);
    const customerRateList = customerRateSnapshot.docs.map((doc) => doc.data());
    return customerRateList;
  }
);

const customerRateSlice = createSlice({
  name: "customerRates",
  initialState: {
    rates: [],
    status: "idle", // idle, loading, succeeded, failed
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerRates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCustomerRates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rates = action.payload;
      })
      .addCase(fetchCustomerRates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default customerRateSlice.reducer;
