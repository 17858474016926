import React from 'react';
import "../css/lightboxStyles.css";

const Lightbox = ({ images, currentIndex, onClose, onNext, onPrev }) => {
  // Bu fonksiyon modal dışında bir yere tıklanıldığında modalı kapatır
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="lightbox" onClick={handleOutsideClick}>
      <button className="close-lightbox" onClick={onClose}>×</button>
      <button className="prev" onClick={onPrev}>&lt;</button>
      <img src={images[currentIndex]} alt="Lightbox" />
      <button className="next" onClick={onNext}>&gt;</button>
    </div>
  );
};

export default Lightbox;
