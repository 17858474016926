import React from 'react'

export default function ShowroomPage() {
  return (
    <section className="section" id="promotion">
          <div className="container">
              <div className="row">
                  <div className="left-image col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix-big"
                      data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                      <img src="images/left-image.png" className="rounded img-fluid d-block mx-auto" alt="App" />
                  </div>
                  <div className="right-text offset-lg-1 col-lg-6 col-md-12 col-sm-12 mobile-bottom-fix">
                      <ul>
                          <li data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                              <img src="images/about-icon-01.png" alt="" />
                              <div className="text">
                                  <h4>Cross-Platform Excellence</h4>
                                  <p>Create apps that work flawlessly on both iOS and Android. Enjoy consistent performance across all devices.</p>
                              </div>
                          </li>
                          <li data-scroll-reveal="enter right move 30px over 0.6s after 0.5s">
                              <img src="images/about-icon-02.png" alt="" />
                              <div className="text">
                                  <h4>Enhanced Performance and Speed</h4>
                                  <p>Achieve fast, responsive apps with smooth user experiences. Optimize performance and speed for better user satisfaction.</p>
                              </div>
                          </li>
                          <li data-scroll-reveal="enter right move 30px over 0.6s after 0.6s">
                              <img src="images/about-icon-03.png" alt="" />
                              <div className="text">
                                  <h4>Rapid Development and Updates</h4>
                                  <p>Speed up development and updates. Quickly adapt to market changes and user needs with efficient processes.</p>
                              </div>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </section>
  )
}
